
    export default {
        props : ['blok','enableSlider','enableArrows'],
        data() {
            return {
                speed : 10,
                speedControl: undefined,
                mooving : false,
                scrollPane : null,
                barPercent : 0,
                scrollableWidth : 0,
                bar: null,
                timeoutHolder: null,
                grabbing: false,
                scrollPaneScrollLeft: 0,
                move : 0,
                moveMax: 0,
                mouseUp: true,
                direction: 'right',
                checkMoveHandler : null
            }
        },
        computed : {
            mouseX(){
                return this.$store.state.mouse.realx
            },
            barMarginLeft(){
                return ((100-this.barPercent) * (this.scrollPaneScrollLeft/this.scrollableWidth)) + '%';
            },
            barWidth(){
                return this.barPercent + '%';
            },
            mw(){
                return this.$store.state.scroll.mw;
            },
        },
        beforeDestroy(){
            if(this.checkMoveHandler) cancelAnimationFrame(this.checkMoveHandler);
        },
        watch : {
        },
        mounted() {
            if(this.$refs.scrollPane){
                this.$refs.scrollPane.addEventListener("scroll",this.handleScroll);
            }
            window.addEventListener("mouseup",this.handleMouseUp);
            this.calcScrollDimensions();
            this.checkMove();
        },
        methods: {
            handleMouseUp(){
                this.move = 0
            },
            checkMove(){
                if(this.scrollableWidth > 0 && this.$refs && this.$refs.scrollPane && this.$refs.scrollPane.scrollLeft >= 0){
                    this.$refs.scrollPane.scrollLeft += this.move;
                    // console.log('*****',this.$refs.scrollPane.scrollLeft, this.move, this.moveMax)
                    if(this.mouseUp && ((this.direction == 'right' && this.$refs.scrollPane.scrollLeft >= this.moveMax )|| (this.direction =='left' && this.$refs.scrollPane.scrollLeft <= this.moveMax  ))){
                        this.move = 0
                    }
                    
                }
                this.checkMoveHandler = requestAnimationFrame(this.checkMove);
            },
            handleScroll(e){
                this.scrollPaneScrollLeft = parseInt(e.target.scrollLeft);
            },
            scroll(direction){
                this.direction = direction
                this.mouseUp = false;
                clearTimeout(this.speedControl)
                this.speedControl = setTimeout(()=> {
                    this.move =this.speed * (this.direction == 'right' ? 1 : -1 )
                    if(this.mouseUp){
                        this.moveMax= this.$refs.scrollPane.scrollLeft +   this.mw * 10 * (this.direction == 'right' ? 1 : -1 )
                    }
                }, 200)
            
            },
            calcScrollDimensions(){
                if(!this.$el){
                    this.$lerpFunction(this.calcScrollDimensions,100,this);
                }else{
                    this.checkDimensions();
                }
            },
            checkDimensions(){
                const scrollWidth = this.$refs.scrollPane.scrollWidth;
                const maxWidth = this.$el.clientWidth;
                this.scrollableWidth = scrollWidth - maxWidth;
                if(this.scrollableWidth){
                    const barWidth = scrollWidth - this.scrollableWidth;
                    this.barPercent = (barWidth/scrollWidth*100);
                    if(this.timeoutHolder) clearTimeout(this.timeoutHolder);
                }
                if(this.timeoutHolder) clearTimeout(this.timeoutHolder);
                this.timeoutHolder = setTimeout(this.checkDimensions, 1000);
            }
        },
        beforeDestroy() {
            if(this.speedControl) clearTimeout(this.speedControl);
            if(this.timeoutHolder) clearTimeout(this.timeoutHolder);
            if(this.$refs.scrollPane) this.$refs.scrollPane.removeEventListener("scroll",this.handleScroll);
            window.removeEventListener('mouseup',this.handleMouseUp)
        },
    }
